// tslint:disable:no-console
import { Spacing, Color } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'

export const SearchLoadingStyled = styled.div`
  position: absolute;
  right: ${Spacing.Small};
  top: 27px;
  color: ${Color.GrayDark};
`
