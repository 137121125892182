// tslint:disable:no-console
import { hasWindow } from 'utils/browser'
import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import React, { useEffect } from 'react'
import worker from 'workerize-loader!./fuse-search.worker'
import { DebouncedSearch } from '../legacy/mol.search/debounced-search.component'
import { SearchQueryParam } from '../legacy/mol.search/debounced-search.utils'
import { IndexParams } from './fuse-search.utils'

export interface FuseSearchProps<D> {
  indexParams: IndexParams<D>
  onResultChange: (result: D) => any
  label?: string
  placeholder?: string
  id?: string
}

export const FuseSearch: React.FunctionComponent<FuseSearchProps<any>> = props => {
  const searchWorker = React.useRef<{ search: Function; buildIndex: Function }>()

  const currentQueryParam: Partial<SearchQueryParam> = queryString.parse(
    hasWindow() ? window.location.search : ''
  )
  useEffect(() => {
    searchWorker.current = new worker() as any
    searchWorker.current.buildIndex(props.indexParams).then(() => {
      if (currentQueryParam.busca) {
        searchData(currentQueryParam.busca)
      }
    })
  }, [])

  const searchData = React.useCallback(
    (searchValue: string) => {
      if (searchValue.length > 0) {
        searchWorker.current.search(searchValue).then(result => {
          props.onResultChange(result)
        })
      } else {
        props.onResultChange(props.indexParams.data)
      }
    },
    [searchWorker.current]
  )

  const handleValueChange = React.useCallback(
    value => {
      searchData(value)

      const nextQueryParam: Partial<SearchQueryParam> = {
        ...currentQueryParam,
        busca: value,
        page: 1
      }
      const pathname = hasWindow() ? window.location.pathname : ''
      navigate(`${pathname}?${queryString.stringify(nextQueryParam)}`)
    },
    [currentQueryParam]
  )

  const handleSubmit = React.useCallback(
    data => {
      if (!data) {
        searchData('')
        return
      }
      searchData(data)
    },
    [searchData]
  )

  return (
    <DebouncedSearch
      placeholder={props.placeholder}
      label={props.label}
      onValueChange={handleValueChange}
      onSubmit={handleSubmit}
      id={props.id}
    />
  )
}
