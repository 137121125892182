import { Border, Color, H3, Spacing } from 'atomic'
import ColorFunc from 'color'
import styled from 'styled-components'

export const TextStyled = styled(H3)<{ selected: boolean }>`
  color: ${props => (props.selected ? props.theme.color.primary : Color.GrayXDark)};
`
export const TextCellStyled = styled.div<{ selected: boolean }>`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: ${Spacing.XLarge} ${Spacing.Medium};
  border: ${props =>
    props.selected ? `2px solid ${props.theme.color.primary}` : `1px solid ${Color.Gray}`};
  border-radius: ${Border.Radius};
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${ColorFunc(Color.Gray)
      .alpha(0.1)
      .hsl()
      .string()};
  }
  :active {
    background-color: ${ColorFunc(Color.Gray)
      .alpha(0.2)
      .hsl()
      .string()};
  }
`
