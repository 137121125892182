import * as React from 'react'
import { TextCellStyled, TextStyled } from './text-cell.style'

export interface TextCellProps {
  text: string
  onClick?: () => any
  selected?: boolean
}

const TextCell: React.FunctionComponent<TextCellProps> = props => (
  <TextCellStyled selected={props.selected} onClick={props.onClick}>
    <TextStyled cell selected={props.selected}>
      {props.text}
    </TextStyled>
  </TextCellStyled>
)

export default TextCell
