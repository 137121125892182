import { ActivityIndicator } from 'atomic/legacy/atm.activity-indicator'
import { TextFieldNew } from '@root/../atomic/legacy/atm.text-field-new'
import { TextField } from 'atomic/legacy/atm.text-field'
import { Form } from 'atomic/legacy/obj.form'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import { hasWindow } from 'utils/browser'
import { debounceWithLimit } from 'utils/debounce/debounce'
import * as queryString from 'query-string'
import React, { useEffect } from 'react'
import { SearchLoadingStyled } from './debounced-search.component.style'
import { SearchQueryParam } from './debounced-search.utils'

export interface DebouncedSearchProps {
  label?: string
  placeholder?: string
  id?: string
  loading?: boolean
  onSubmit: (value: string) => void
  onValueChange: (value: string) => void
}

export const DebouncedSearch: React.FunctionComponent<DebouncedSearchProps> = props => {
  const debouncedHandleChange = React.useRef<any>()
  useEffect(() => {
    debouncedHandleChange.current = debounceWithLimit(props.onValueChange, 3)
  }, [props.onValueChange])

  const handleValueChange = React.useCallback(value => {
    debouncedHandleChange.current(value)
  }, [])
  const handleSubmit = React.useCallback(
    value => {
      props.onSubmit(value.data.search)
    },
    [props.onSubmit]
  )

  const search: Partial<SearchQueryParam> = queryString.parse(
    hasWindow() ? window.location.search : ''
  )
  return (
    <RelativeWrapper>
      <Form id="form-debounced-search" onSubmit={handleSubmit}>
        <Form.Field
          initialValue={search.busca}
          name="search"
          label={props.label}
          onValueChange={handleValueChange}
        >
          {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' ? (
            <TextFieldNew placeholder={props.placeholder} id={props.id} />
          ) : (
            <TextField placeholder={props.placeholder} id={props.id} />
          )}
        </Form.Field>
        {props.loading && (
          <SearchLoadingStyled>
            <ActivityIndicator type="spinner" />
          </SearchLoadingStyled>
        )}
      </Form>
    </RelativeWrapper>
  )
}
