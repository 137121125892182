import { Col, Row, H1 } from 'atomic'
import styled from 'styled-components'
import * as React from 'react'
import TextCell from '../../../components/atm.text-cell/text-cell.component'
import ConvenioDetailRow from './convenio-detail-row.component'

export interface PlanoConvenio {
  name: string
}
export interface Convenio {
  name: string
  listaDePlanos: PlanoConvenio[]
  listaDePlanosDestaques: PlanoConvenio[]
  detailSlug: string
}

interface ConveniosAccordionProps {
  convenios: Convenio[]
  conveniosDestaques: Convenio[]
  groupSize: 1 | 2 | 3 | 4 | 6 | 12
}

const H1Margin = styled(H1)`
  margin: ${props => (props.margin ? props.margin : '20px 0')};
`

const noIndexSelected = -1

export const ConveniosAccordion: React.FunctionComponent<ConveniosAccordionProps> = props => {
  const groupBy = props.groupSize
  const colSize = 12 / groupBy
  const conveniosGroupedBy = getConveniosGroupedBy(props.convenios, groupBy)
  const conveniosDestaquesGroupedBy = getConveniosGroupedBy(props.conveniosDestaques, groupBy)
  const [selectedIndex, setSelectedIndex] = React.useState()
  const [selectedFeaturedIndex, setSelectedFeaturedIndex] = React.useState()
  const handleClick = index => {
    return () => {
      if (selectedIndex === index) {
        setSelectedIndex(noIndexSelected)
      } else {
        setSelectedIndex(index)
      }
    }
  }
  const handleFeaturedClick = index => {
    return () => {
      if (selectedFeaturedIndex === index) {
        setSelectedFeaturedIndex(noIndexSelected)
      } else {
        console.log(props.conveniosDestaques[index])
        setSelectedFeaturedIndex(index)
      }
    }
  }

  return (
    <>
      {conveniosDestaquesGroupedBy.length > 0 && (
        <React.Fragment>
          <H1Margin>Convênios mais utilizados</H1Margin>
          {conveniosDestaquesGroupedBy.map((group, groupIndex) => (
            <React.Fragment key={group.id}>
              <Row mb>
                {group.convenios.map((item, itemIndex) => (
                  <Col
                    className="test-convenios-item"
                    xs={colSize}
                    key={item.name}
                    id={`test-convenios-item-${itemIndex}`}
                  >
                    <TextCell
                      text={item.name}
                      selected={selectedFeaturedIndex === groupIndex * groupBy + itemIndex}
                      onClick={handleFeaturedClick(groupIndex * groupBy + itemIndex)}
                    />
                  </Col>
                ))}
              </Row>
              {showRow(selectedFeaturedIndex, groupIndex, groupBy) && (
                <ConvenioDetailRow
                  convenio={props.conveniosDestaques[selectedFeaturedIndex]}
                  key={selectedFeaturedIndex}
                  arrowColSize={colSize}
                  arrowOffset={getArrowOffset(selectedFeaturedIndex, groupBy)}
                  listaDePlanos={
                    selectedFeaturedIndex !== noIndexSelected &&
                    props.conveniosDestaques[selectedFeaturedIndex].listaDePlanos
                  }
                  listaDePlanosDestaques={
                    selectedFeaturedIndex !== noIndexSelected &&
                    props.conveniosDestaques[selectedFeaturedIndex].listaDePlanosDestaques
                  }
                  detailSlug={
                    selectedFeaturedIndex !== noIndexSelected &&
                    props.conveniosDestaques[selectedFeaturedIndex].detailSlug
                  }
                  onClose={handleFeaturedClick(selectedFeaturedIndex)}
                />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
      {conveniosDestaquesGroupedBy.length > 0 && (
        <H1Margin margin="60px 0 20px">Outros convênios</H1Margin>
      )}
      {conveniosGroupedBy.map((group, groupIndex) => (
        <React.Fragment key={group.id}>
          <Row mb>
            {group.convenios.map((item, itemIndex) => (
              <Col
                className="test-convenios-item"
                xs={colSize}
                key={item.name}
                id={`test-convenios-item-${itemIndex}`}
              >
                <TextCell
                  text={item.name}
                  selected={selectedIndex === groupIndex * groupBy + itemIndex}
                  onClick={handleClick(groupIndex * groupBy + itemIndex)}
                />
              </Col>
            ))}
          </Row>
          {showRow(selectedIndex, groupIndex, groupBy) && (
            <ConvenioDetailRow
              convenio={props.convenios[selectedIndex]}
              key={selectedIndex}
              arrowColSize={colSize}
              arrowOffset={getArrowOffset(selectedIndex, groupBy)}
              listaDePlanos={
                selectedIndex !== noIndexSelected && props.convenios[selectedIndex].listaDePlanos
              }
              listaDePlanosDestaques={
                selectedIndex !== noIndexSelected &&
                props.convenios[selectedIndex].listaDePlanosDestaques
              }
              detailSlug={
                selectedIndex !== noIndexSelected && props.convenios[selectedIndex].detailSlug
              }
              onClose={handleClick(selectedIndex)}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}

const getArrowOffset = (selectedIndex: number, groupSize: number) => {
  return (selectedIndex % groupSize) * (12 / groupSize)
}

const showRow = (selectedIndex: number, groupIndex: number, groupSize: number) => {
  return groupIndex === Math.floor(selectedIndex / groupSize)
}

interface ConveniosGroup {
  id: string
  convenios: Convenio[]
}
const getConveniosGroupedBy = (convenios: Convenio[], groupBy: number) => {
  const conveniosGrouped: ConveniosGroup[] = []
  for (let index = 0; index < convenios.length; index = index + groupBy) {
    conveniosGrouped.push({
      id: `${index}`,
      convenios: convenios.slice(index, index + groupBy)
    })
  }
  return conveniosGrouped
}
